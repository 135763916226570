import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ animationData }) => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    // Charge et joue l'animation
    const anim = lottie.loadAnimation({
      container: lottieContainer.current, // Référence au conteneur de l'animation
      renderer: 'svg',
      loop: false, // Ne pas boucler automatiquement
      autoplay: true, // Jouer automatiquement dès que possible
      animationData: animationData,
    });

    // Fonction pour redémarrer l'animation
    const restartAnimation = () => {
      anim.goToAndPlay(0, true);
    };

    // Définir un intervalle pour redémarrer l'animation toutes les 10 secondes
    const intervalId = setInterval(restartAnimation, 10000);

    // Nettoyage: arrêter l'animation et l'intervalle quand le composant est démonté ou que les dépendances changent
    return () => {
      clearInterval(intervalId); // Arrête l'intervalle
      anim.destroy(); // Détruit l'animation
    };
  }, [animationData]); // Dépendances de l'effet

  return <div ref={lottieContainer} style={{ width: '97px', height: '100px' }}></div>;
};

export default LottieAnimation;