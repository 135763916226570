// src/components/Chatbot.js
import React, { useState } from 'react';
import './Chatbot.css'; // Nous allons créer ce fichier CSS pour styliser notre chatbot
//import chatIcon from '../assets/img/cine974-compagnon.png';
import loaderIcon from '../assets/img/Spinner-1s-48px.gif';
import LottieAnimation from './LottieAnimation';
import animationData from '../assets/json/animation.json';


const Chatbot = () => {


	const scrollToTop = () => {
	    const scrollContainer = document.querySelector('.chat-window');
	    if (scrollContainer) {
	        scrollContainer.scrollTo({
	            top: 0,
	            left: 0,
	            behavior: 'smooth'
	        });
	    }
	};



	// Adaptez la fonction pour ajouter un événement clic directement sur l'élément créé
	document.addEventListener('click', function (e) {
	    if (e.target.matches('.trailer-option, .trailer-option *')) {
	        const messageIndex = Array.from(document.querySelectorAll('.trailer-option')).indexOf(e.target.closest('.trailer-option'));
	        const message = messages[messageIndex];
	        if (message && message.action) message.action();
	    }
	    if (e.target.matches('.salle-option, .salle-option *')) {
	        const messageIndex = Array.from(document.querySelectorAll('.salle-option')).indexOf(e.target.closest('.salle-option'));
	        const message = messages[messageIndex];
	        if (message && message.action) message.action();
	    }
	});


	/* ########################################################### */
	/* ########################################################### */
	/* ####################### TRAILERS ########################## */
	/* ########################################################### */
	/* ########################################################### */
    
    const viewTrailers = () => {
	    setMessages([]); // Vide les messages
	    sendMessage("<h3>Voici les catégories de bandes annonces disponibles :</h3>", false);
	    setShowBackButton(true);
	    
	    const trailerCategories = [
	        { text: "Dernières BA", category: "latest" },
	        { text: "BA Films en Salle", category: "in-theater" },
	        { text: "BA Prochaines sorties", category: "upcoming" },
	        { text: "BA Meilleurs Films", category: "top-rated" },
	        { text: "BA Séries", category: "series" }
	    ];

	    setCurrentChoices(trailerCategories.map(option => ({
	        text: option.text,
	        action: () => loadTrailersForCategory(option.category)
	    })));
	};

	const showTrailer = (url,title,urlfilm) => {
		scrollToTop();
	    setMessages([
	        { 
	            text: `<h3 class="tleft">Bande annonce ${title}</h3>
	                   <video controls autoplay style="max-width: 100%; height: auto;">
	                       <source src="${url}" type="video/mp4">
	                       Your browser does not support the video tag.
	                   </video><br><a href=${urlfilm} class="link" target="_top" style="margin-bottom:30px;display:inline-block;">Fiche du film &rarr;</a>`, 
	            isUser: false 
	        }
	    ]);
	};


	const loadTrailersForCategory = async (category) => {
	    setMessages([]); // Vide les messages avant de charger de nouveaux
	    scrollToTop();
	    sendMessage(`<h3>Chargement des bandes annonces pour ${category}...</h3>`, false);
	    setShowBackButton(true);

	    try {
	        const trailersData = await fetchTrailersByCategory(category);
	        const trailerOptions = trailersData.map(trailer => ({
	            title: trailer.title,
	            image: trailer.image,
	            videoUrl: trailer.videoUrl,
	            filmUrl: trailer.filmUrl,
	            action: () => showTrailer(trailer.videoUrl, trailer.title, trailer.filmUrl)
	        }));



	        // Afficher les bandes annonces sous forme de liste dans les messages
	        setMessages(trailerOptions.map(option => ({
	            text: `<div class="trailer-option" style="cursor: pointer;" >
	                      <h4>${option.title}</h4><img src="${option.image}" alt="${option.title}" />
	                      
	                   </div>`,
	            isUser: false,
	            action: option.action
	        })));
	    } catch (error) {
	        sendMessage("Désolé, je ne peux pas récupérer les bandes annonces actuellement.", false);
	    }
	};



	// Fonction pour récupérer les bandes annonces par catégorie
	const fetchTrailersByCategory = async (category) => {
	    // Construire l'URL de l'API en incluant la catégorie spécifique
	    const url = `https://api.cine974.com/rest/v2/get/Chatbot/Trailers?category=${encodeURIComponent(category)}&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`;
	    
	    try {
	        const response = await fetch(url);  // Faire l'appel API
	        if (!response.ok) {
	            throw new Error('Network response was not ok');
	        }
	        const data = await response.json();  // Parse la réponse en JSON
	        
	        // Vérifier si la data est comme attendue, sinon adapter ou gérer les erreurs
	        return data.map(trailer => ({
	            title: trailer.title,
	            image: trailer.image,
	            videoUrl: trailer.videoUrl,
	            filmUrl: trailer.filmUrl
	        }));
	    } catch (error) {
	        console.error("Failed to fetch trailers:", error);
	        // Gérer l'erreur, par exemple en retournant un tableau vide ou en affichant un message
	        return [];
	    }
	};
	

	

	/* ########################################################### */
	/* ########################################################### */
	/* ########################## SALLES ######################### */
	/* ########################################################### */
	/* ########################################################### */

	const viewSalles = async () => {
		setIsLoading(true);
	    setMessages([]); // Vide les messages
	    sendMessage("<h3>Voici les salles de cinéma disponibles :</h3>", false);
	    setShowBackButton(true);

	    try {
	        const sallesData = await fetchSalles();
	        const sallesOptions = sallesData.map(salle => ({
	            title: salle.nom,
	            image: salle.image,
	            salleId: salle.id,
	            action: () => showSalleDetails(salle.id)
	        }));

	        // Afficher les salles sous forme de liste dans les messages
	        setMessages(sallesOptions.map(option => ({
	            text: `<div class="salle-option" style="cursor: pointer;" >
	                      <h4>${option.title}</h4><img src="${option.image}" alt="${option.title}" style="width: 100%;" />
	                   </div>`,
	            isUser: false,
	            action: option.action
	        })));
	    } catch (error) {
	        sendMessage("Désolé, je ne peux pas récupérer les salles actuellement.", false);
	    }
	    const sallesOptions = [
	        { text: "Autres salles (spectacles, théâtres...)", url: "https://www.cine974.com/salles-cinema-reunion/" },
	    ];
	    setIsLoading(false);
	    setCurrentChoices(sallesOptions.map(option => ({
	        text: option.text,
	        action: () => redirectToUrl(option.url)
	    })));
	};


	const showSalleDetails = async (salleId) => {
		setMessages([]); // Vide les messages
	    setIsLoading(true);
	    try {
	        const response = await fetch(`https://api.cine974.com/rest/v2/get/Chatbot/Salle?salleID=${salleId}&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`);
	        const salleDetails = await response.json();
	        if (salleDetails) {
	        	const truncatedDescription = salleDetails.cinema_description.length > 100 ? 
                                         salleDetails.cinema_description.substring(0, 100) + "..." : 
                                         salleDetails.cinema_description;
	            const detailsMessage = `
	                <h3>${salleDetails.cinema_nom}</h3>
	                <img src="${salleDetails.image}" alt="${salleDetails.nom}" style="width:100%; max-width: 300px;">
	                
	                <p>${salleDetails.nbfilms} films en salle actuellement<br />${salleDetails.nbevents} évènements à venir<br />
	                	<a href="https://www.cine974.com/salles-cinema-reunion/${salleDetails.cinema_nom_format}/${salleDetails.cinema_id}/" target="_top" class="link">Séances et évènements &rarr;</a></p>
	                
	                <p>${truncatedDescription} 
	                	<a href="https://www.cine974.com/salles-cinema-reunion/${salleDetails.cinema_nom_format}/${salleDetails.cinema_id}/" target="_top" class="link">Lire la suite</a></p>
	            `;
	            setMessages([{ text: detailsMessage, isUser: false }]);
	        } else {
	            sendMessage("Détails non disponibles pour cette salle.", false);
	        }
	    } catch (error) {
	        console.error("Erreur lors de la récupération des détails de la salle:", error);
	        sendMessage("Impossible de charger les détails de la salle.", false);
	    }
	    /*const sallesOptions = [
	        { text: "Autres salles (spectacles, théâtres...)", url: "https://www.cine974.com/salles-cinema-reunion/${salleDetails.cinema_nom_format}/${salleDetails.cinema_id}/" },
	    ];*/
	    setIsLoading(false);
	    /*setCurrentChoices(sallesOptions.map(option => ({
	        text: option.text,
	        action: () => redirectToUrl(option.url)
	    })));*/

	    const sallesOptions = [
	        { text: "Retour aux salles", action: viewSalles }
	    ];

	    setCurrentChoices(sallesOptions);
	};


	const fetchSalles = async () => {
	    const url = `https://api.cine974.com/rest/v2/get/Salles?zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`;
	    try {
	        const response = await fetch(url);
	        if (!response.ok) {
	            throw new Error('Network response was not ok');
	        }
	        const data = await response.json();
	        // Filtrer les données pour inclure uniquement les salles de type 'cinema'
	        const filteredData = data.filter(salle => salle.type === 'cinema');
	        return filteredData.map(salle => ({
	            id: salle.cinema_id,
	            nom: salle.cinema_nom,
	            nom_format: salle.cinema_nom_format,
	            image: salle.illustration,
	            description: salle.cinema_description,
	            nbfilms: salle.nbfilms,
	            nbevents: salle.nbevents,
	            telephone: salle.cinema_tel
	        }));
	    } catch (error) {
	        console.error("Failed to fetch salles:", error);
	        return [];
	    }
	};





	

	/* ########################################################### */
	/* ########################################################### */
	/* ################## SUGGESTIONS FILMS ###################### */
	/* ########################################################### */
	/* ########################################################### */
	const viewDivertissement = () => {
		setMessages([]); // Vide les messages
	    sendMessage("<h3>Plutôt film ou série ?</h3>", false);
	    setShowBackButton(true);
	    
	    // Nouvelles options spécifiques à viewTrailers
	    const divertissementOptions = [
	        { text: "Film", action: viewDivertissementFilm },
	        { text: "Série", action: viewDivertissementSerie },
	    ];

	    // Mettre à jour l'état pour inclure ces nouveaux choix
	    setCurrentChoices(divertissementOptions);
	};

	const viewDivertissementFilm = () => {
		setMessages([]); // Vide les messages
	    sendMessage("<h3>En salle ou en streaming ?</h3>", false);
	    setShowBackButton(true);
	    
	    const divertissementFilmOptions = [
	        { text: "En salle, au cinéma", action: viewDivertissementFilmCinema },
	        { text: "En streaming, dans mon canap'", action: viewDivertissementFilmStreaming },
	    ];

	    setCurrentChoices(divertissementFilmOptions);
	};

	
	const viewDivertissementFilmCinema = () => {
		setMessages([]); // Vide les messages
	    sendMessage("<h3>Quand ?</h3>", false);
	    setShowBackButton(true);
	    
	    const divertissementOptionsQuand = [
	        { text: "Aujourd'hui", quand: "aujourdhui" },
	        { text: "Demain", quand: "demain" },
	        { text: "Prochains jours", quand: "avenir" }
	    ];

	    setCurrentChoices(divertissementOptionsQuand.map(option => ({
	        text: option.text,
	        action: () => viewFilmCinemaRegion(option.quand)
	    })));
	};

	const viewFilmCinemaRegion = (quand, region) => {
	    setMessages([]); // Vide les messages
	    sendMessage(`<h3>Où ?</h3>`, false);
	    setShowBackButton(true);
	    
	    const divertissementOptionsRegion = [
	        { text: "Nord", region: "Nord" },
	        { text: "Sud", region: "Sud" },
	        { text: "Ouest", region: "Ouest" },
	        { text: "Est", region: "Est" }

	    ];

	    setCurrentChoices(divertissementOptionsRegion.map(option => ({
	        text: option.text,
	        action: () => viewFilmCinemaPourQui(quand, option.region) // Utiliser une fonction pour afficher le contenu
	    })));
	};

	const viewFilmCinemaPourQui = (quand, region, pourqui) => {
	    setMessages([]); // Vide les messages
	    sendMessage(`<h3>Pour qui dans ${region} ?</h3>`, false);
	    setShowBackButton(true);
	    
	    const divertissementOptions = [
	        { text: "En Amoureux", pourqui: 'Amoureux' },
	        { text: "En Solo", pourqui: 'Solo' },
	        { text: "En Famille", pourqui: 'Famille' },
	        { text: "Marmailles", pourqui: 'Marmailles' },
	        { text: "Entre Amis", pourqui: 'Amis' },

	    ];

	    setCurrentChoices(divertissementOptions.map(option => ({
	        text: option.text,
	        action: () => viewFilmCinemaContent(quand, region, option.pourqui) // Utiliser une fonction pour afficher le contenu
	    })));
	};

	const viewFilmCinemaContent = async(quand, region, pourqui) => {
	    console.log(`quand sélectionné: ${quand}, region sélectionnée: ${region}, Pour qui sélectionné: ${pourqui}`);
	    // Logique pour afficher le contenu spécifique au genre sélectionné sur la plateforme choisie

	    setIsLoading(true); // Commencer à afficher le loader
		setMessages([]); // Vide les messages
	    setShowBackButton(true);
	    try {
		    const apiRouteUrl = `https://api.cine974.com/rest/v2/get/Chatbot/FilmCinema?quand=${quand}&region=${region}&pourqui=${pourqui}&type=film&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`;
		    console.log(apiRouteUrl);
		    const response = await fetch(apiRouteUrl);
		    const data = await response.json();
		    if (data && data.length > 0) {
		        let filmListMessage = "<h3>Voici mes suggestions</h3>";
		        filmListMessage += "<ul class='moviesListe'>";
		        data.forEach(film => {
		            const filmUrl = film.url; 
		            filmListMessage += `<li><a href="${filmUrl}" target="_top">${film.titre}<br/><span>de ${film.realisateurs}</span><br/><span>en salle à ${film.ensalle}</span></a></li>`;
		        });
		        filmListMessage += "</ul>";
		        sendMessage(filmListMessage, false);
		    } else {
		        sendMessage("Aucun film trouvé selon ces critères.", false);
		    }
		} catch (error) {
		    sendMessage("Désolé, une erreur est survenue lors de la récupération des films.", false);
		}

	    const filmsOptions = [
	    	{ text: "Tous les films en salle", url: "https://www.cine974.com/films/" },
	        { text: "Tous les films en streaming", url: "https://www.cine974.com/svod/" },
	        { text: "Tous les films gratuits", url: "https://www.cine974.com/streaming/" },
	    ];

	    setIsLoading(false); // Arrêter d'afficher le loader

	    // Mettre à jour l'état pour inclure ces nouveaux choix
	    setCurrentChoices(filmsOptions.map(option => ({
	        text: option.text,
	        action: () => redirectToUrl(option.url)
	    })));
	};







	/* ########################################################### */
	/* ########################################################### */
	/* #################### SUGGESTION STREAMING ################# */
	/* ########################################################### */
	/* ########################################################### */

	const viewDivertissementFilmStreaming = () => {
	    setMessages([]); // Vide les messages
	    sendMessage("<h3>Quelle plateforme ?</h3>", false);
	    setShowBackButton(true);
	    
	    const divertissementFilmStreamingOptions = [
	        { text: "Netflix", platform: "Netflix" },
	        { text: "Amazon Prime Video", platform: "AmazonPrimeVideo" },
	        { text: "Disney+", platform: "Disney" },
	        { text: "Apple TV+", platform: "AppleTV" },
	        { text: "Canal+", platform: "Canal" },
	        { text: "Paramount+", platform: "Paramount" },
	        { text: "Films Gratuits", platform: "Gratuit" },
	    ];

	    setCurrentChoices(divertissementFilmStreamingOptions.map(option => ({
	        text: option.text,
	        action: () => viewStreamingPlatform(option.platform)
	    })));
	};

	const viewStreamingPlatform = (platform, genre) => {
	    setMessages([]); // Vide les messages
	    sendMessage(`<h3>Quel genre sur ${platform} ?</h3>`, false);
	    setShowBackButton(true);
	    
	    const divertissementOptions = [
	        { text: "Comédie", genre: 'Comédie' },
	        { text: "Romance", genre: 'Romance' },
	        { text: "Science-fiction", genre: 'Science-fiction' },
	        { text: "Action", genre: 'Action' },
	        { text: "Animation", genre: 'Animation' },
	        { text: "Aventure", genre: 'Aventure' },
	        { text: "Thriller", genre: 'Thriller' },

	        // films de noel
	        // films d'horreur
	    ];

	    setCurrentChoices(divertissementOptions.map(option => ({
	        text: option.text,
	        action: () => viewStreamingContent(platform, option.genre) // Utiliser une fonction pour afficher le contenu
	    })));
	};

	const viewStreamingContent = async(platform, genre) => {
	    console.log(`Plateforme sélectionnée: ${platform}, Genre sélectionné: ${genre}`);
	    // Logique pour afficher le contenu spécifique au genre sélectionné sur la plateforme choisie

	    setIsLoading(true); // Commencer à afficher le loader
		setMessages([]); // Vide les messages
	    setShowBackButton(true);
	    try {
		    const apiRouteUrl = `https://api.cine974.com/rest/v2/get/Chatbot/Streaming?plateforme=${platform}&genre=${genre}&type=film&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`;
		    console.log(apiRouteUrl);
		    const response = await fetch(apiRouteUrl);
		    const data = await response.json();
		    if (data && data.length > 0) {
		        let filmListMessage = "<h3>Voici mes suggestions</h3>";
		        filmListMessage += "<ul class='moviesListe'>";
		        data.forEach(film => {
		            const filmUrl = film.url; 
		            filmListMessage += `<li><a href="${filmUrl}" target="_top">${film.titre}<br/><span>de ${film.realisateurs}</span></a></li>`;
		        });
		        filmListMessage += "</ul>";
		        sendMessage(filmListMessage, false);
		    } else {
		        sendMessage("Aucun film trouvé selon ces critères.", false);
		    }
		} catch (error) {
		    sendMessage("Désolé, une erreur est survenue lors de la récupération des films.", false);
		}

	    const filmsOptions = [
	        { text: "Tous les films en streaming", url: "https://www.cine974.com/svod/" },
	        { text: "Tous les films gratuits", url: "https://www.cine974.com/streaming/" },
	    ];

	    setIsLoading(false); // Arrêter d'afficher le loader

	    // Mettre à jour l'état pour inclure ces nouveaux choix
	    setCurrentChoices(filmsOptions.map(option => ({
	        text: option.text,
	        action: () => redirectToUrl(option.url)
	    })));
	};



	

	/* ########################################################### */
	/* ########################################################### */
	/* ##################### SUGGESTION SERIE #################### */
	/* ########################################################### */
	/* ########################################################### */

	const viewDivertissementSerie = () => {
	    setMessages([]); // Vide les messages
	    sendMessage("<h3>Quelle plateforme ?</h3>", false);
	    setShowBackButton(true);
	    
	    const divertissementSerieStreamingOptions = [
	        { text: "Netflix", platform: "Netflix" },
	        { text: "Amazon Prime Video", platform: "AmazonPrimeVideo" },
	        { text: "Disney+", platform: "Disney" },
	        { text: "Apple TV+", platform: "AppleTV" },
	        { text: "Canal+", platform: "Canal" },
	        { text: "Paramount+", platform: "Paramount" },
	    ];

	    setCurrentChoices(divertissementSerieStreamingOptions.map(option => ({
	        text: option.text,
	        action: () => viewStreamingSeriePlatform(option.platform)
	    })));
	};

	const viewStreamingSeriePlatform = (platform, genre) => {
	    setMessages([]); // Vide les messages
	    sendMessage(`<h3>Quel genre sur ${platform} ?</h3>`, false);
	    setShowBackButton(true);
	    
	    const divertissementSerieOptions = [
	        { text: "Comédie", genre: 'Comédie' },
	        { text: "Romance", genre: 'Romance' },
	        { text: "Science-fiction", genre: 'Science-fiction' },
	        { text: "Action", genre: 'Action' },
	        { text: "Animation", genre: 'Animation' },
	        { text: "Aventure", genre: 'Aventure' },
	        // Ajoutez d'autres genres si nécessaire
	    ];

	    setCurrentChoices(divertissementSerieOptions.map(option => ({
	        text: option.text,
	        action: () => viewStreamingSerieContent(platform, option.genre) // Utiliser une fonction pour afficher le contenu
	    })));
	};

	const viewStreamingSerieContent = async(platform, genre) => {
	    console.log(`Plateforme sélectionnée: ${platform}, Genre sélectionné: ${genre}`);
	    // Logique pour afficher le contenu spécifique au genre sélectionné sur la plateforme choisie

	    setIsLoading(true); // Commencer à afficher le loader
		setMessages([]); // Vide les messages
	    setShowBackButton(true);
	    try {
		    const apiRouteUrl = `https://api.cine974.com/rest/v2/get/Chatbot/Streaming?plateforme=${platform}&genre=${genre}&type=serie&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`;
		    console.log(apiRouteUrl);
		    const response = await fetch(apiRouteUrl);
		    const data = await response.json();
		    if (data && data.length > 0) {
		        let serieListMessage = "<h3>Voici mes suggestions</h3>";
		        serieListMessage += "<ul class='moviesListe'>";
		        data.forEach(serie => {
		            const serieUrl = serie.url; 
		            serieListMessage += `<li><a href="${serieUrl}" target="_top">${serie.titre} <span>(${serie.annee})</span></a></li>`;
		        });
		        serieListMessage += "</ul>";
		        sendMessage(serieListMessage, false);
		    } else {
		        sendMessage("Aucun série trouvée selon ces critères.", false);
		    }
		} catch (error) {
		    sendMessage("Désolé, une erreur est survenue lors de la récupération des séries.", false);
		}

	    const seriesOptions = [
	        { text: "Voir tous les séries en streaming", url: "https://www.cine974.com/series/" },
	    ];

	    setIsLoading(false); // Arrêter d'afficher le loader

	    // Mettre à jour l'état pour inclure ces nouveaux choix
	    setCurrentChoices(seriesOptions.map(option => ({
	        text: option.text,
	        action: () => redirectToUrl(option.url)
	    })));
	};





	/* ########################################################### */
	/* ########################################################### */
	/* ######################## ARTICLES ######################### */
	/* ########################################################### */
	/* ########################################################### */


	const fetchArticlesByCategory = async (categoryUrl) => {
		setIsLoading(true); // Commencer à afficher le loader
		scrollToTop();
	    try {
	    	const apiRouteUrl = `https://api.cine974.com/rest/v2/get/Chatbot/Blog?category=${categoryUrl}&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`;
	        const response = await fetch(apiRouteUrl);
	        if (!response.ok) {
	            throw new Error('Network response was not ok');
	        }
	        const articles = await response.json();
	        return articles; // Supposons que la réponse contient un tableau d'articles
	    } catch (error) {
	        console.error("Erreur lors de la récupération des articles:", error);
	        return []; // Renvoyer un tableau vide en cas d'erreur
	    }

	};



	const viewNews = async () => {
		
	    setMessages([]); // Vide les messages
	    sendMessage("<h3>Quel type d'actualité t'intéresse ?</h3>", false);
	    setShowBackButton(true);
	    
	    const newsOptions = [
	        { text: "Actu Ciné", url: "cine" },
	        { text: "Actu People", url: "people" },
	        { text: "Actu des Séries", url: "series" },
	        { text: "Agenda des Évènements", url: "evenements" },
	        { text: "Insolites", url: "insolites" },
	        { text: "Critiques", url: "critiques" },
	    ];

	    setCurrentChoices(newsOptions.map(option => ({
	        text: option.text,
	        action: async () => {
	            const articles = await fetchArticlesByCategory(option.url);
	            setMessages(articles.map(article => ({
	                text: `<div class="news-option" style="cursor: pointer;" ><h4>${article.title}</h4><img src=${article.image} alt=${article.title} /><p>${article.subtitle} <a href='${article.url}' target='_top' class='link'>Lire l'article &rarr;</a></p></div>`,
	                isUser: false
	            })));
	            setIsLoading(false); // Arrêter d'afficher le loader
	        }
	    })));

	    
	};






	/* ########################################################### */
	/* ########################################################### */
	/* ##################### FILMS EN SALLE ###################### */
	/* ########################################################### */
	/* ########################################################### */


	const fetchMoviesInCinema = async () => {
		setIsLoading(true); // Commencer à afficher le loader
	    setMessages([]); // Vide les messages
	    setShowBackButton(true);
	    try {
	        const response = await fetch('https://api.cine974.com/rest/v2/get/Films/EnSalle?zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4');
	        const data = await response.json();
	        if (data && data.length > 0) {
	            // Commence par un message d'introduction
	            let movieListMessage = "<h3>Voici les films en salle à La Réunion</h3>";
	            // Début de la liste
	            movieListMessage += "<ul class='moviesListe'>";
	            // Ajout de chaque film comme élément de liste
	            data.forEach(movie => {
	                const movieUrl = `https://www.cine974.com/film/${encodeURI(movie.titre.replace(/\s+/g, '-').toLowerCase())}/${movie.film_id}/`;
	                movieListMessage += `<li><a href="${movieUrl}" target="_top">${movie.titre}<br/><span>de ${movie.realisateurs}</span></a></li>`;
	            });
	            // Fin de la liste
	            movieListMessage += "</ul>";
	            // Envoie le message de la liste complète
	            sendMessage(movieListMessage, false);
	        } else {
	            sendMessage("Il n'y a actuellement aucun film en salle à La Réunion.", false);
	        }
	    } catch (error) {
	        sendMessage("Désolé, je ne peux pas récupérer les films en salle actuellement.", false);
	    }

	    const moviesOptions = [
	        { text: "Prochaines sorties", url: "https://www.cine974.com/sorties-cinema/a-la-reunion/" },
	        { text: "Films en streaming", url: "https://www.cine974.com/svod/" },
	        { text: "Films gratuits", url: "https://www.cine974.com/streaming/" },
	    ];

	    setIsLoading(false); // Arrêter d'afficher le loader

	    // Mettre à jour l'état pour inclure ces nouveaux choix
	    setCurrentChoices(moviesOptions.map(option => ({
	        text: option.text,
	        action: () => redirectToUrl(option.url)
	    })));
	};








	/* ########################################################### */
	/* ########################################################### */
	/* ########################### BASE ########################## */
	/* ########################################################### */
	/* ########################################################### */

	const initialChoices = [
		{ text: "Propose-moi quelque chose à regarder", action: viewDivertissement},
	    { text: "Montre-moi des bandes annonces", action: viewTrailers },
	    { text: "Affiche-moi les films en salle", action: fetchMoviesInCinema},
	    { text: "Présente-moi mon cinéma préféré", action: viewSalles},
	    { text: "Parle-moi des actualités ciné, séries, people", action: viewNews},
	    
	];

	const [isOpen, setIsOpen] = useState(false); // État pour gérer si le chatbot est ouvert ou fermé
    const [messages, setMessages] = useState([
        { text: "<h2>Salut, je m'appelle <strong>Mina</strong>.<br/>Comment puis-je t'aider ?</h2>", isUser: false }
    ]);
    const [currentChoices, setCurrentChoices] = useState(initialChoices); // initialChoices est votre choix initial.
	const [showBackButton, setShowBackButton] = useState(false);
	//const [currentMode, setCurrentMode] = useState(null);
	const [isLoading, setIsLoading] = useState(false);



    // Fonction pour basculer l'état d'ouverture/fermeture
    const toggleChatbot = () => {
        setIsOpen(!isOpen);
        window.parent.postMessage('toggleChatbot', '*');
    };

   

    const redirectToUrl = (url) => {
	    window.parent.location.href = url;
	};

    
    const sendMessage = (text, type = 'general', isUserMessage = false) => {
	    const newMessage = { text, type, isUser: isUserMessage };
	    setMessages(currentMessages => [...currentMessages, newMessage]);
	};
   
    


	const handleBackButton = () => {
		setMessages([]); 
		sendMessage("<h2>Salut, je m'appelle <strong>Mina</strong>.<br/>Comment puis-je t'aider ?</h2>", false);
	    setCurrentChoices(initialChoices);
	    setShowBackButton(false);
	    
	};

    

    return (
        <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
            <button onClick={toggleChatbot} className="chatbot-toggle" style={{ border: 'none', background: 'none' }}>
                <LottieAnimation animationData={animationData}  />
            </button>
            {isOpen && (

            	<div className="chat-window">

            		<button className="chat-fermer" onClick={toggleChatbot}>X</button>


            		{showBackButton && (
				        <button onClick={handleBackButton} className="chat-back-button">
				            &larr;
				        </button>
				    )}

				    {isLoading && <div className="loader"><img src={loaderIcon} alt="Chargement" /></div>}

				    <div className="chat-messages">
				        {messages.map((message, index) => (
				            <div key={index} className={`message ${message.isUser ? 'user-message' : 'bot-message'}`}>
				                
				                <span dangerouslySetInnerHTML={{ __html: message.text }}></span>
				            </div>
				        ))}
				    </div>

				    {currentChoices.map((choice, index) => (
				        <button key={index} onClick={() => choice.action()} className="chat-choice">
				            {choice.text}
				        </button>
				    ))}

				    <p className="chat-informations">
				    	Mina - compagnon cinéma - V1
				    </p>

				    
				</div>
                
            )}
        </div>
    );
};

export default Chatbot;