// src/App.js
import React from 'react';
import './App.css';
import Chatbot from './components/Chatbot';

function App() {
  return (
    <div className="App">
      {/* D'autres composants de votre application */}
      <Chatbot /> {/* Intégration du Chatbot */}
    </div>
  );
}

export default App;